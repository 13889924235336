import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App, Send } from './App';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('app')!).render(
    <StrictMode>
        <App wallet={true} transaction={false} />
    </StrictMode>
);


createRoot(document.getElementById('sendbtn')!).render(
    <StrictMode>
        <App wallet={false} transaction={true} />
    </StrictMode>
);

